export const deepCopy = x => JSON.parse(JSON.stringify(x));

export const pickRandom = ls => ls[Math.floor(Math.random()*ls.length)];



const { sqrt, random, log, floor, ceil } = Math;

let gaussCache = [];
export const Random = {
    coin: (bound = 0.5) => random() <= bound,
    uniform: random,
    uniformIn: (from, to) => from + (to - from)*random(),
    unilateral: random,
    bilateral: () => 2*random() - 1,
    pick: (ls, weights = false) => {
        if (!weights) return ls[floor(random()*ls.length)];

        let totalWeight = weights.reduce((sum, w) => sum + w, 0);
        let r = totalWeight*Random.uniform();
        for (let i = 0, sum = weights[0]; i < ls.length; sum += weights[++i]) {
            if (!ls[i]) debugger;
            if (r < sum) return ls[i];
        }
        return ls[ls.length - 1];
    },
    gaussian: () => {
        // derived from https://jsfiddle.net/ssell/qzzvruc4/
        if (gaussCache.length) return gaussCache.pop();

        let x1 = 0, x2 = 0, w = 0;
        do {
            x1 = Random.bilateral();
            x2 = Random.bilateral();
            w  = x1**2 + x2**2;
        } while(w >= 1);
  
        w = sqrt((-2 * log(w)) / w);

        gaussCache.push(x2 * w);
        return x1 * w;
    },
    shuffle: ls => {
        // see fisher-yates shuffle on wikipedia
        let res = [ ...ls ];
        for (let i = ls.length - 1; i > 0; --i) {
            let j = floor(Random.uniformIn(0, i + 1));
            [ res[i], res[j] ] = [ res[j], res[i] ];
        }

        return res;
    }
};