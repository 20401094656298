
<script>
import { mapGetters, mapState } from "vuex";


export default {
    props: [ "ids", "ingredients" ],
    computed: {
        names() {
            let ingredients = this.ingredients || this.ids.map(id => this.$store.getters.ingredientTable[id] );
            return ingredients.map(i => i.name.toLowerCase());
        }
    },
    render(h) {
        let { names } = this;
        let ls;
        let attrs = { attrs: { class: '' } };
        if (names.length == 0) ls = [];
        if (names.length == 1) ls = [ h("span", attrs, names[0]) ];
        else {
            let lastName = names.pop();
            let secondLastName = names.pop();

            ls = [
                ...names.map(n => h("span", attrs, n + ", ")),
                h("span", attrs, secondLastName),
                " and ",
                h("span", attrs, lastName)
            ];
        }

        return h("span", ls);
    }
}
</script>

<style>

</style>