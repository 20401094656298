<template>
    <main class="page page-evolution">
        <h2 class="section-title">The Ultimate Bake-off</h2>

        <header>
            <h3>Generation {{ Number(selectedGen) + 1 }}</h3>

            <div class="control-bar">
                <div class="tab-group">
                    <span
                        class="tab" :class="{ 'tab--active': view == 'tournament'}"
                        @click="view = 'tournament'"
                    >
                        Tournament
                    </span>
                    <span
                        class="tab" :class="{ 'tab--active': view == 'cookbook'}"
                        @click="view = 'cookbook'"
                    >
                        Cookbook
                    </span>
                </div>
                <div class="button-group">
                    <button @click="reset" type="button">
                        Start over
                    </button>

                    <div>
                        <label>Go to:</label>
                        <select v-model="selectedGen">
                            <option v-for="(gen, i) in history" :key="i" :value="i">
                                Generation {{ i + 1 }}
                            </option>
                        </select>
                    </div>

                    
                    <button @click="nextGeneration" type="button">
                        Next generation
                    </button>
                </div>
            </div>
        </header>

        <recipe-list v-if="view == 'tournament'" :recipes="currentRecipes" class="evolved-recipes" />
        <div v-if="view == 'cookbook'" class="cookbook-container">
            <ol class="cookbook">
                <li class="cookbook-cover">
                    <img src="@/assets/logo.png" class="cookbook-logo" alt="Darwin's Bakery, EST. 1984" />
                    <h1>Cookbook <br /> <small>of</small> <br /> Champions</h1>
                    <span><em v-html="generationName(selectedGen)" /></span>
                </li>
                <li class="cookbook-page">
                </li>
                <li class="cookbook-page cookbook-index-page">
                    <h2>Index</h2>
                    <ol class="index-list">
                        <li
                            v-for="(r,i) in currentChampions" :key="i"
                            class="index-item"
                        >
                            <span class="index-name recipe-name">{{ r.name }}</span>
                            <span class="index-deco"/>
                            <span class="index-pageno">{{ i + 1}}</span>
                        </li>
                    </ol>
                </li>

                <li 
                    v-for="(r, i) in currentChampions" :key="i"
                    class="cookbook-page cookbook-page--recipe"
                >
                    <header class="cookbook-header">
                        <h2>{{ r.name }}</h2>
                    </header>
                    <aside class="cookbook-sidebar">
                        <div class="cookbook-sidebar-section">
                            <strong>Ingredients</strong>
                            <ul>
                                <li 
                                    v-for="ingredient in mapIngredients(r.ingredients)" :key="ingredient.id"
                                    class="cookbook-ingredient"
                                >
                                    {{ ingredient.name }}

                                </li>
                            </ul>
                        </div>
                        <div class="cookbook-sidebar-section">
                            <strong>Total cost</strong> <br />
                            <span>{{ getCost(r) }} silver coins</span>
                        </div>
                        <div class="cookbook-sidebar-section">
                            <strong>Effects</strong>
                            <ul>
                                <li 
                                    v-for="(e, t) in getEffects(r)" :key="t"
                                    class="cookbook-stat"
                                >
                                    <span>{{ t }}:</span>
                                    <span>{{ e }}</span>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <aside class="cookbook-gallery">
                        <span class="cookbook-image" :style="{
                            transform: `scale(${r.render.scale}, ${r.render.scale}) rotate(${r.render.rotation}turn)`,
                            filter: `hue-rotate(${ r.render.color }deg) saturate(${ r.render.saturation }%)`
                        }">
                            {{ r.render.icon }}
                        </span>
                    </aside>
                    <section class="cookbook-recipe-container">
                        <h3>Instructions</h3>
                        <ol class="cookbook-instruction-list">
                            <li 
                                v-for="(instr, i) in r.instructions" :key="i"
                                class="cookbook-instruction"
                            >
                            <span class="cookbook-instruction-step">{{ i + 1 }}</span>
                            <p v-if="instr.type == 'mix'">
                                Mix the <ingredient-text-list :ids="instr.ingredients" /> in a bowl.
                            </p>
                            <p v-else-if="instr.type == 'knead'">
                                Knead the dough until it gains a uniform consistency.
                            </p>
                            <p v-else-if="instr.type == 'knead-in'">
                                While kneading, slowly add the <ingredient-text-list :ids="instr.ingredients" />.
                            </p>
                            <p v-else-if="instr.type == 'rest'">
                                Cover the bowl and let it rest for {{ instr.time }}.
                            </p>
                            <p v-else-if="instr.type == 'cool'">
                                Cover the bowl. Put it in the fridge and let it cool for {{ instr.time }}.
                            </p>
                            <p v-else-if="instr.type == 'shape'">
                                <!-- Random.pick([ "ball", "flattened", "cutter", "pan" ] -->
                                <template v-if="instr.variant == 'cutter'">
                                    Flatten the dough into a sheet roughly 1cm thick and use your cookie cutter to cut out shapes.
                                </template>
                                <template v-else-if="instr.variant == 'ball'">
                                    Make dough balls of around 3cm in diameter and gently put them on your oven tray. Leave enough space between the dough balls.
                                </template>
                                <template v-else-if="instr.variant == 'flattened'">
                                    Make dough balls of around 4cm in diameter and put them on your oven tray. Flatten the dough balls gently. Make sure to leave some space between the cookies.
                                </template>
                            <p v-else-if="instr.type == 'coat'">
                                Coat the cookies with {{ instr.ingredients.length ? "a mixture of " : "" }} the <ingredient-text-list :ids="instr.ingredients" />.
                            </p>
                            <p v-else-if="instr.type == 'bake'">
                                Put the cookies in the oven on {{ instr.temperature }}°C and let them bake for {{ instr.time }}. Remove them from the oven and let them cool off.
                            </p>
                            <p v-else-if="instr.type == 'glaze'">
                                Use a brush to glaze the cookies with {{ instr.ingredients.length ? "a mixture of " : "" }} the <ingredient-text-list :ids="instr.ingredients" />.
                            </p>
                            <p v-else-if="instr.type == 'sprinkle'">
                                Sprinkle some <ingredient-text-list :ids="instr.ingredients" /> on top of the cookies for a final touch.
                            <p v-else>
                                UNHANDLED INSTRUCTION TYPE {{ instr.type }} ({{ mapIngredients(instr.ingredients || []).map(ing => ing.name).join() }})
                            </p>

                            </li>
                        </ol>
                    </section>
                    <footer class="cookbook-footer">
                        {{ i + 1}}
                    </footer>
                    
                </li>

                <li class="cookbook-page">
                </li>
                <li class="cookbook-cover">
                </li>
            </ol>

        </div>
            
    </main>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import * as cookiemon from "@/cookiemon.js";

import RecipeList from "@/components/RecipeList.vue";
import IngredientTextList from "@/components/IngredientTextList.vue";

import { Random } from "@/utils.js";

export default {
    components: { RecipeList, IngredientTextList },
    data() {
        return {
            history: [],
            selectedGen: 0,
            view: "cookbook"
        };
    },
    created() { if (this.history.length == 0) this.nextGeneration(); },
    computed: {
        ...mapState([ "recipeList", "ingredientList" ]),
        ...mapGetters([ "recipeTable", "ingredientTable" ]),
        currentGeneration() {
            if (this.history.length) {
                return this.history[this.selectedGen];
            }
        },
        currentRecipes() {
            if (this.history.length) {
                return [
                    ...this.currentGeneration.mutated,
                    ...this.currentGeneration.rounds[0].recipes,
                    ...this.currentGeneration.rounds[1].recipes,
                ];
            }
        },
        currentChampions() {
            if (this.currentGeneration) {
                return this.currentGeneration.rounds[1].recipes;
            }
        }
    },
    methods: {
        generationName(gen) {
            let suffixMap = { 0: "st", 1: "nd", 2: "rd" };
            let suffix = suffixMap[gen] || "th";
            return `${gen + 1}<sup>${suffix}</sup> Generation`;
        },
        mapIngredients(ingredients) {
            return ingredients.map(id => this.ingredientTable[id]);
        },
        getEffects: cookiemon.getEffects,
        getCost: cookiemon.recipeCost,

        reset() {
            this.history = [];
            this.nextGeneration();
        },
        nextGeneration() {
            let population;
            if (this.history.length == 0) {
                population = [];
                while (population.length < 32) {
                    population.push(Random.pick(this.recipeList));
                }
            } else { // this.history.length > 0
                population = this.currentGeneration.offspring;
            }

            population = Random.shuffle(population);
            this.history.push(cookiemon.executeStep(population));

            this.selectedGen = this.history.length - 1;
        }
    }
}
</script>

<style lang="scss">

.page-evolution {
    display: grid;
    grid-template-rows: auto auto 1fr;

    .button-group {
        margin-bottom: 16px;
    }
}



.cookbook-container {
    background: #cad5e1;
    display: flex;

    // justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
}

.cookbook-cover, .cookbook-page {
    width: calc(297px * 2);
    height: calc(420px * 2);
    margin: 16px;

    break-after: always;

    h1 {
        line-height: 0.75;
        font-size: 64px;
    }
    h2 {
        font-size: 36px;
        line-height: .95;
        text-transform: lowercase;
        margin-bottom: 16px;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}
.cookbook-cover {
    position: relative;
    display: flex;
    background: darkslategray;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: palegoldenrod;

    &:nth-last-child(1) {
        &:before {
            content: "";
            position: absolute;
            display: block;

            width: calc(100% - 96px);
            height: calc(100% - 96px);

            border: 6px double #eee8aa45;
        }
    }

    h1 {
        text-align: center;
        margin-bottom: 16px;
        border-bottom: 1px solid #eee8aa45;
        padding: 0 48px 24px 48px;
    }

    small { font-size: .6em; font-weight: 400;  }
}
.cookbook-page {
    padding: 64px 48px;
    background: white;

    &:nth-child(even) {
        padding-right: 84px;
        .cookbook-footer { text-align: left; }
    }
    &:nth-child(odd)  {
        padding-left:  84px;
        .cookbook-footer { text-align: right; }
    }
}

.cookbook-page--recipe {
    display: grid;
    grid-template-areas:
        "header   header"
        "sidebar gallery"
        "sidebar  recipe"
        "footer   footer";
    
    grid-template-columns: 144px 1fr;
    grid-template-rows: auto auto 1fr auto;
}

.cookbook-header { grid-area: header; }
.cookbook-sidebar {
    grid-area: sidebar;
    padding-right: 24px;
}
.cookbook-gallery {
    position: relative;
    height: 200px;
    background: #eee;
    grid-area: gallery;
    overflow: hidden;
    margin-bottom: 8px;

    .cookbook-image {
        position: absolute;
        display: block;
        width: 100%;
        opacity: .8;

        font-size: 120px;
        text-align: center;
    }
}
.cookbook-footer {
    grid-area: footer;
    align-self: end;
}

.cookbook-logo {
    width: 80%;
    display: block;
    margin-bottom: 20%;
    filter: brightness(2) hue-rotate(20deg);
}

.index-list {
    margin-left: 24px;
}
.index-item {
    margin: 4px 0;
    display: flex;
}
.index-deco {
    flex-grow: 1;
    border-bottom: 2px dotted #ccc;
    margin: 3px 4px;

}



.cookbook-sidebar-section {
    margin-bottom: 16px;
}

.cookbook-stat {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
}

.cookbook-instruction-list {
    margin-top: 16px;
}
.cookbook-instruction {
    display: flex;
    margin-bottom: 8px;
    p { margin: 0; }
}
.cookbook-instruction-step {
    display: block;
    width: 1.5em;
    flex: 0 0 auto;

    &:after { content: "."; }
}



.sidebar-layout {
    display: flex;
    justify-content: space-between;
}



.tab-group {
    display: flex;
    padding: 8px;

    font: 400 16px "BasicSans", sans-serif;

}
.tab {
    position: relative;
    display: block;
    padding: 8px 12px;
    margin-left: 8px;

    font: 400 1em/1 "BasicSans", sans-serif;


    cursor: pointer;
    background: #eee;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid gray;
    box-shadow: 0 -10px 10px -10px #ccc inset;

    &:hover {
        background: #fafafa;
    }
}
.tab--active {
    background: white;
    border: 1px solid gray;
    border-bottom: 0;
    box-shadow: none;

    
    &:before, &:after {
        content: "";
        position: absolute;
        bottom: 0;
        display: block;
        width: 100vw;
        border-bottom: 1px solid gray;
    }

    &:before { right: 100%; }
    &:after { left: 100%; }
}


.evolved-recipes {
    margin-top: 16px;
    overflow: hidden;
    padding: 16px;

    background: #cad5e1;

    .recipe-details {
        display: block;
        width: 200px;
        margin-left: 16px;
        background: white;
        padding: 16px;
        max-height: 100%;
        overflow: hidden;
        overflow-y: auto;
        border-radius: 8px;
        flex: 0 0 auto;
    }
    .recipe-columns {
        display: block;
    }

    .recipe-list {
        height: 100%;
        overflow: auto;
        padding-right: 16px;
        // flex-grow: 1;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            " a1  b1 c1"
            " a2  b1 c1"
            " a3  b2 c1"
            " a4  b2 c1"
            " a5  b3 c2"
            " a6  b3 c2"
            " a7  b4 c2"
            " a8  b4 c2"
            " a9  b5 c3"
            "a10  b5 c3"
            "a11  b6 c3"
            "a12  b6 c3"
            "a13  b7 c4"
            "a14  b7 c4"
            "a15  b8 c4"
            "a16  b8 c4"
            "a17  b9 c5"
            "a18  b9 c5"
            "a19 b10 c5"
            "a20 b10 c5"
            "a21 b11 c6"
            "a22 b11 c6"
            "a23 b12 c6"
            "a24 b12 c6"
            "a25 b13 c7"
            "a26 b13 c7"
            "a27 b14 c7"
            "a28 b14 c7"
            "a29 b15 c8"
            "a30 b15 c8"
            "a31 b16 c8"
            "a32 b16 c8";
        column-gap: 8px;
        row-gap: 8px;

    }

    .recipe-entry {
        position: relative;
        display: flex;
        height: calc(16px + 1.1 * 4 * 1em);
        padding: 8px 32px;
        align-self: center;
        overflow: visible;
        
        justify-content: center;
        align-items: center;

        border-radius: 8px;
        background: white;
        text-shadow: 0 2px 0 white;
        line-height: 1.1;


        @for $i from 1 through 32 {
            &:nth-child(#{$i}) {
                grid-area: a#{$i}
            }
        }
        @for $i from 33 through 48 {
            &:nth-child(#{$i}) {
                grid-area: b#{$i - 32};

                &:before, &:after {
                    content: "";
                    display: block;
                    position: absolute;

                    border: 0 dashed gray;
                    width: 500px;
                    height: 20px;
                    right: 50%;
                    width: calc(50% + 8px);

                }

                &:before {
                    border-width: 1px 1px 0 0;
                    bottom: 100%;
                    border-top-right-radius: 16px;
                }
                &:after {
                    top: 100%;
                    border-width: 0 1px 1px 0;
                    border-bottom-right-radius: 16px;
                }
            }
        }

        @for $i from 49 through 56 {
            &:nth-child(#{$i}) {
                grid-area: c#{$i - 48};

                &:before, &:after {
                    content: "";
                    display: block;
                    position: absolute;

                    border: 0 dashed gray;
                    width: 500px;
                    height: calc(50% + 8px);
                    right: 50%;
                    width: calc(50% + 8px);

                }

                &:before {
                    border-width: 1px 1px 0 0;
                    bottom: 100%;
                    border-top-right-radius: 16px;
                }
                &:after {
                    top: 100%;
                    border-width: 0 1px 1px 0;
                    border-bottom-right-radius: 16px;
                }
            }

        }
    }
}

</style>