<template>
  <div class="recipe-list-container">
    <ul class="recipe-list">
        <li
            class="recipe-entry"
            v-for="(r, i) in recipes" :key="i"
            @mouseenter="selectedRecipe = r"
            @click="clickRecipe(r, $event)"
        >
            <span class="recipe-name">{{ r.name }}</span>
            
            <template v-if="showMeta">
                <span class="recipe-meta">
                    <span class="recipe-meta-label">Ingredients:</span>
                    {{ r.ingredients.length }}
                </span>
                <span class="recipe-meta">
                    <span class="recipe-meta-label">Cost:</span>
                    {{ getCost(r) }}
                </span>
            </template>
        </li>
    </ul>

    <aside class="recipe-details">
        <template v-if="selectedRecipe">
            <header>
                <h1 class="recipe-name">
                    {{ selectedRecipe.name }}
                </h1>
            </header>
            <div class="recipe-detail-data">
                <div class="recipe-detail-item recipe-detail-ingredient">
                    <strong>Ingredients:</strong>
                    <ul>
                        <li v-for="(ing, i) in selectedRecipe.ingredients" :key="i">
                            {{ ingredientTable[ing].name }}
                        </li>
                    </ul>
                </div>
                <div class="recipe-detail-item">
                    <strong>Cost:</strong> {{ selectedCost }} coins
                </div>
                <div class="recipe-detail-item">
                    <strong>Multiplier:</strong> {{ selectedMultiplier }}
                </div>
                <div class="recipe-detail-item">
                    <strong>Effects:</strong>
                    <ul>
                        <li v-for="(t, i) in statTypes" :key="i">
                            <span class="recipe-stat-name">{{t}}:</span>
                            {{ selectedEffects[t] }} ({{ selectedEffects[t]*selectedMultiplier }})
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </aside>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import * as cookiemon from "@/cookiemon.js";

export default {
    props: [ "recipes", "showMeta" ],
    data() {
        return {
            selectedRecipe: null
        };
    },
    computed: {
        ...mapState([ "recipeList", "ingredientList" ]),
        ...mapGetters([ "recipeTable", "ingredientTable" ]),
        statTypes() { return cookiemon.statTypes; },
        selectedEffects() {
            if (!this.selectedRecipe) return null;

            return cookiemon.getEffects(this.selectedRecipe);
        },
        selectedCost() {
            if (!this.selectedRecipe) return 0;
            return cookiemon.recipeCost(this.selectedRecipe);
        },
        selectedMultiplier() {
            if (!this.selectedRecipe) return 0;

            return cookiemon.getMultiplier(this.selectedRecipe);
        }
    },
    methods: {
        getCost(recipe) { return cookiemon.recipeCost(recipe); },
        clickRecipe(recipe, event) {
            this.$emit("click-recipe", { recipe, event });
        }
    }
};
</script>

<style lang="scss">

.recipe-list-container {
    display: grid;
    grid-template-columns: auto auto;
}


.recipe-details {
    align-self: start;

    &:empty { visibility: hidden; }

    .recipe-name {
        font: 600 24px/.9 "BasicSans", sans-serif;
        line-height: .9;
        padding-bottom: 4px;
        border-bottom: 4px solid black;
    }

    text-transform: capitalize;

    strong {
        font: 600 1em/.9 "BasicSans", sans-serif;
    }
}

.recipe-detail-item {
    margin-top: 8px;
    ul {
        border-top: 1px solid black;
        // margin-top: 2px;
        padding-top: 2px;
    }
}


</style>