<template>
  <section
        class="modal-container"
        @click.self="close"
      >
        <div class="modal recipe-modal">
          <header class="modal-header">
            <slot name="title"><h1>{{ title }}</h1></slot>
            <button class="modal-close-button" @click="close">
              ✖
            </button>
          </header>
          <div class="modal-body">
            <slot />
          </div>
          <footer class="modal-footer">
            <slot name="footer" />
          </footer>
        </div>
      </section>
</template>

<script>
export default {
    props: [ "title" ],
    methods: {
        close(e) { this.$emit("close", e); }
    }
}
</script>

<style lang="scss">

.modal-container {
    position: fixed;
    top: 0; left: 0;
    display: flex;
    width: 100%; height: 100%;

    justify-content: center;
    align-items: center;

    background: rgba(0,0,0, 0.7);
}

.modal {
    background: white;
    border-radius: 4px;
}
.modal-header, .modal-footer {
    padding: 16px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-body {
    padding: 0 16px;
    max-height: 80vh;
    overflow: auto;
}

</style>