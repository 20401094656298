import Vue from 'vue'
import Vuex from 'vuex'

import ingredients from "@/assets/ingredients.json";
import fantasyIngredients from "@/assets/fantasy-ingredients.json";
import recipes from "@/assets/recipes.json";

Vue.use(Vuex)



let idchars = "ABCDEFGHIKJLMNOPQRSTUVWXYZ";
let makeID = () => {
  let id = "";
  for (let i = 0; i < 6; ++i) {
    id += idchars[Math.floor(Math.random()*idchars.length)];
  }
  return id;
}




export default new Vuex.Store({
  state: {
    ingredientList: [],
    recipeList: [],
  },
  getters: {
    ingredientTable: state => {
      const ingredients = {};
      state.ingredientList.forEach(ing => ingredients[ing.id] = ing);
      return ingredients;
    },
    recipeTable: state => {
      const recipes = {};
      state.recipeList.forEach(r => recipes[r.id] = r);
      return recipes;
    },
  },
  mutations: {
    insertIngredientList(state, list) { state.ingredientList = list; },
    insertRecipeList(state, list) { state.recipeList = list; },
    addIngredient(state, ing) { state.ingredientList.push(ing); },
    removeIngredient(state, id) { state.ingredientList = state.ingredientList.filter(ing => ing.id != id)},
    updateIngredient(state, update) {
      let i = state.ingredientList.findIndex(ing => ing.id == update.id);
      if (i >= 0) {
        state.ingredientList[i] = update;
      }
    },
    addRecipe(state, r) { state.recipeList.push(r); },
    removeRecipe(state, id) { state.recipeList = state.recipeList.filter(r => r.id != id)},
    updateRecipe(state, update) {
      let i = state.recipeList.findIndex(r => r.id == update.id);
      if (i >= 0) {
        state.recipeList[i] = update;
      }
    },
  },
  actions: {
    resetIngredients({ dispatch }) { dispatch("importIngredients", ingredients); },
    resetRecipes({ dispatch }) { dispatch("importRecipes", recipes); },

    importIngredients({ commit, dispatch }, data) {
      commit("insertIngredientList", data);
      return dispatch("store");
    },
    importRecipes({ commit, dispatch }, data) {
      commit("insertRecipeList", data);
      return dispatch("store");
    },
    load({ commit }) {
      try {
        const storedIngredients = localStorage["COOKIEMON_INGREDIENTS"]
        let parsedIngredients = false;
        if (storedIngredients) parsedIngredients = JSON.parse(storedIngredients);
        commit("insertIngredientList", parsedIngredients || fantasyIngredients);
      } catch {
        commit("insertIngredientList", fantasyIngredients);
      }
      try {
        const storedRecipes = localStorage["COOKIEMON_RECIPES"]
        let parsedRecipes = false;
        if (storedRecipes) parsedRecipes = JSON.parse(storedRecipes);
        commit("insertRecipeList", parsedRecipes || recipes);
      } catch {
        commit("insertRecipeList", recipes);
      }
    },
    store({ state }) {
      localStorage["COOKIEMON_INGREDIENTS"] = JSON.stringify(state.ingredientList);
      localStorage["COOKIEMON_RECIPES"] = JSON.stringify(state.recipeList);
    },
    addIngredient({ commit, dispatch, getters }, ing) {
      let id = makeID();
      while (id in getters.ingredientTable) {
        id = makeID();
      }
      ing = { id, ...ing };
      commit("addIngredient", ing);
      return dispatch("store");
    },
    removeIngredient({ commit, dispatch }, id) {
      commit("removeIngredient", id);
      return dispatch("store");
    },
    updateIngredient({ commit, dispatch }, ing) {
      commit("updateIngredient", ing);
      return dispatch("store");
    },
    
    // addIngredientToRecipe({ commit, dispatch }) {
    //   dispatch("store");
    // },
    // removeIngredientFromRecipe({ commit, dispatch }) {
    //   dispatch("store");
    // },
    
    addRecipe({ commit, dispatch, getters }, r) {
      let id = makeID();
      while (id in getters.recipeTable) {
        id = makeID();
      }
      r = { id, ...r };
      commit("addRecipe", r);
      dispatch("store");
    },
    removeRecipe({ commit, dispatch }, id) {
      commit("removeRecipe", id);
      dispatch("store");
    },
    updateRecipe({ commit, dispatch }, r) {
      commit("updateRecipe", r);
      dispatch("store");
    },
  },
  modules: {
  }
})
