<template>
  <main class="page page-home text-section">
    <h2 class="section-title">Darwins Bakery</h2>
    
    <p>
      Darwins Bakery is the ultimate bake off. At each round, 32 bakers face off to decide who has the best cookie recipe. Recipes are judged on their cost and how well they aid in battle, as the bakers defend their recipe in a fight to the death. The eight fittest bakers are randomly paired with each other to breed the next generation of contestants.
    </p>



    <h2 class="section-title">Process</h2>
 
    <p>
      Darwins Bakery uses an evolutionary algorithm to generate novel recipes. We chose to incorporate a fight system and ingredient stats to fit two themes we wanted to incorporate; evolution and fictional ingredients. Letting recipes fight in a tournament is a very literal implementation of 'survival of the fittest', which is the core idea of evolution and evolutionary algorithms.
    </p>
    <p>
      The idea of having recipes fight lead us to a combat system loosely inspired by role playing games. This also created a good opportunity to add content from such games to our knowledge base and inspiring set. We sourced ideas for ingredients from fictional worlds such as Harry Potter and The Witcher, from table top RPGs and from fan-made content. We found it much easier to come up with interesting ingredients for our knowledge base, than recipes for the inspiring set. For this reason, our inspiring set is relatively modest, and we rely on mutation to add interesting variations to the recipe gene pool.
    </p>
    <p>
      The concept of a combat system could be worked out in many ways, and we found that the project invited us to explore ideas in many different directions. Given the time constraints for this project, we were only able to scratch the surface of this idea space. We chose to focus on building a combat system and gathering a inspiring set that would yield interesting results, and on presenting this system and said results in an attractive way. We added constraints to the generator based on ingredient cost, ingredient uniqueness and recipe length, to prevent the optimization process from degenerate recipes. However, the generator still produces recipes that could hardly be called <em>cookie</em> recipes. We also took many shortcuts to present our recipes nicely, and as a result, the presentation often does not fully match the recipe. We feel we could have done more here, for example by checking that the recipe includes some types of ingredients, and generating presentational elements more holistically.
    </p>
    <p>
      Other ideas we would love to explore further include a more fleshed out recipe structure, with different steps and perhaps different ingredient effects based on when and how they are included. For example, some ingredients could give very different effects when they are baked instead of added as a topping. In the same vein, specific combinations of ingredients or ingredient types could also produce enhanced or detrimental effects.
    </p>

 

    <h2 class="section-title">Technical details</h2>
 
    <p>
      Darwins Bakery uses an evolutionary algorithm (EA) with tournament selection, one-point cross-over and several mutation operators. We use a combat system to determine fitness of the entries. We also built a web interface to maintain the underlying data and drive the EA. The user is in control of the generation process, and can see the results and steps of the process. The algorithm is implemented in plain javascript, while the web interface is built using VueJS. The deliverable PDFs are generated by using the browsers print-to-pdf function. The most important details behind the algorithm are described below.
    </p>

    <h3>Recipes and ingredients</h3>

    <p>
      The core of an EA is the representation of its data. Our system operates on <em>recipes</em>, which are represented as a list of <em>ingredients</em>. Each ingredient consists of an id, a name, and several numbers representing <em>health</em>, <em>stamina</em>, <em>strength</em>, <em>speed</em>, <em>evasion</em>, <em>toughness</em> and <em>cost</em>. These numbers, aggregated across the ingredients within a recipe, are used to determine the fitness of a recipe through a combat system. The details of this system will be explained further in the context of the selection rule.
    </p>

    <p>
      Aside from ingredients, we generate several presentational attributes for each recipe, which are not used in the evolutionary system. The recipe name is generated following a template, starting (optionally) with an adjective chosen from a list, followed by one or more ingredient names and finally a synonym for 'cookie', also chosen from a list. The instructions also follow a template. There are several optional steps, which can take ingredients from the recipe. Each step has a templated text associated with it. Finally, we generate a photo for each recipe by choosing an emoji and applying a randomized hue shift, (de)saturation, scale and rotation.
    </p>

    <h3>Selection</h3>

    <p>
      We use a <em>tournament selection</em> rule; recipes are paired up in a two round tournament bracket, and a combat system determines which of two recipes proceeds to the next round. Since a population starts off with 32 recipes, this results in 8 champions.
    </p>
    <p>
      The combat system simulates fights between two agents, until one of them is out of health or stamina. Stamina slowly decreases over time, and also affects attack speed, power and evasion. The speed of an agent is used to determine how often they attack, while their strength is used to calculate the attack damage. The damage is modulated by the toughness of the defending player and is then substracted of their health. Finally, an agents evasion increases its chance to completely dodge an attack.
    </p>
    <p>
      Each agent is created with base values of 100 for every stat. To calculate the effect of its associated recipe, we sum up the value for each stat across ingredients. The recipe also has a cost, wich is the sum of the cost of its ingredients. We allow each agent to spend up to 100 coins on cookies, with a maximum of 6 cookies. The recipe stats are multiplied by the number of cookies, and then added to the base values.
    </p>
    
    <h3>Recombination</h3>

    <p>
      We use a <em>one-point crossover</em> rule, similar to the tutorial. Given two recipes, we randomly generate two splitting points, giving us four recipe halves. We recombine these into two new recipes. We apply this rule to the champions of the last generation until we have a new population of 32 recipes.
    </p>
    
    <h3>Mutation</h3>
    <p>
      We apply a mutation step to each recipe to generate more variety. Each ingredient in a recipe has a chance of 1/(ingredient count) to mutate. When an ingredient mutates, one of three operations is chosen with uniform probability. The mutation operators can either remove the ingredient, replace it by a randomly chosen ingredient, or insert a new ingredient after it. When a recipe has fewer than 3 ingredients, we do not include the removal operator.
    </p>


    <h2 class="section-title">Evaluation of creativity</h2>

    <p>
      Evaluating the creativity of a system is a challenging task. We need to deal with questions such as what creativity is in the first place. This question already has many different answers depending on who you ask. Answers will often mention novelty or the ability to surprise. With this in mind, we try to evaluate the creativity of our system for generating cookie recipes.
    </p>
    <p>
       Ventura in his 2016 paper "Mere Generation: Essential Barometer or Dated Concept?" presented a framework to classify generating systems on a scale. At the beginning of his odyssey of generating systems he places random generation. Just generating random recipes can yield novel and surprising recipes, but can also produce many nonsensical ones. A few steps up the scale he describes systems that are based on some form of evaluation. This is where our system lands in the journey. Ventura calls this type of system a <em>Filtration system</em>. This type of system does not only generate artifacts, but also has a way of evaluating their quality. In our case this means generating recipes that perform well in the combat system. The next step up Ventura's scale is a system that also uses more domain specific knowledge. He calls these types of systems <em>Inception systems</em>. Though you could say that the ingredient effects are a sort of 'domain knowledge', said domain is a somewhat arbitrary system, unrelated to the domain of cookies and baking. To truly climb to this next step of the ladder, we could use and extend the statistics of a recipe to determine if it can be baked, or if it has enough of a certain type of ingredients (flour, sugar, etc.).
    </p>
    <p>
      A problem with our system is that it favours recipes that perform well in the combat system and not if they are good and novel in real life. The choices for the different statistics for the ingredients are hand authored, and have a large impact on which recipes will be generated. In a sense, this means that the authors still decide to some extent which recipes are generated, instead of just the creativity of the system.
    </p>
    <p>
      Another factor we should take into account is that our system produces an endless stream of recipes. Even if we had used a much larger knowledge base of ingredients, it is hard to be truly surprised by the 40th generated recipe from our system.
    </p>
    <p>
      In conclusion, our system does produce novel recipes in the sense that the produced combinations of ingredients and instructions have probably never been seen before. We think our method for determining the quality of the recipes is original, and leads to interesting results. However, the novelty of this system is limited by what the authors put in, and the ability to surprise quickly diminishes over time.
    </p>

  </main>
</template>

<style lang="scss">

</style>


<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {


    

  }
}
</script>