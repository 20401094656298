import { Random } from "./utils";

import store from "@/store/index.js";





export const emptyStats = {
    health: 0,
    stamina: 0,
    speed: 0,
    strength: 0,
    evasion: 0,
    toughness: 0
};
const baseStats = {
    health: 100,
    stamina: 100,
    speed: 100,
    strength: 100,
    evasion: 100,
    toughness: 100
};
export const statTypes = Object.keys(baseStats);


export const attack = (attackStats, attackEffects, defendStats, defendEffects) => {
    if (Math.random() < defendStats.evasion / 1000) {
        // Dodged!
        return false;
    }

    let damage = 5 * attackStats.strength * (attackStats.stamina / 100);
    defendStats.health -= damage / defendStats.toughness;

    return damage;
};

const updateStats = (stats, effects, t) => {
    if (!t) stats.stamina -= 5;
    else    stats.stamina -= t;
};

const startStats = effects => {
    let stats = { ...baseStats };
    statTypes.forEach(s => stats[s] += effects[s]);
    return stats;
}

const timeToAttack = (stats, effects) => {
    let jitter = 1 + 0.1*Random.gaussian();
    return jitter*5/(stats.speed/100);
};
export const fight = (e0, e1) => {
    let e = [ e0, e1 ];
    let stat = e.map(startStats);
    
    let history = [];
    let t = 0;
    let nextT = [ timeToAttack(stat[0], e[0]), timeToAttack(stat[1], e[1]) ];

    let i = 0;
    while (stat.every(s => s.health > 0 && s.stamina > 0)) {
        if (i++ > 1000) {
            debugger;
            break;
        }
        let attacker = nextT[0] < nextT[1] ? 0 : 1;
        let defender = (attacker + 1) % 2;

        let dt = nextT[attacker] - t;
        
        updateStats(stat[0], e[0], dt);
        updateStats(stat[1], e[1], dt);

        let log = { attacker, t, stats: stat.map(s => ({ ...s })), defendStats: false };

        let atk = attack(stat[attacker], e[attacker], stat[defender], e[defender]);
        if (atk !== false) {
            log.defendStats = { ...stat[defender] };
        }
        
        t = nextT[attacker];
        nextT[attacker] += timeToAttack(stat[attacker], e[attacker]);
        history.push(log);
    }

    let winner = stat[0].health < stat[1].health ? 1 : 0;
    return [ winner, history ];
}


export const getEffects = recipe => {
    const effects = { ...emptyStats };
    recipe.ingredients
        .map(id => store.getters.ingredientTable[id])
        .forEach(ing => statTypes.forEach(s => effects[s] += Number(ing[s])));
    return effects;
};


// This could be expanded to see if the ingredients could realistically produce a cookie
export const canBake = recipe => true;

export const recipeCost = recipe => recipe.ingredients
    .map(id => store.getters.ingredientTable[id])
    .reduce((sum, ing) => sum + Number(ing.cost), 0);

export const countBakableAmmount = (recipe, resources = 100) => {
    if (!canBake(recipe)) return 0;

    return Math.floor(resources/recipeCost(recipe));
};

// An unused idea; evaluate how good the cookie tastes, and how many you'd want to eat
export const getFlavorProfile = recipe => { };
export const countEdibleAmmount = flavorProfile => 6;

export const getMultiplier = recipe => Math.min(countBakableAmmount(recipe), countEdibleAmmount(recipe));

// Get the effects of the cookie, and multiply by how many you can/want to eat
export const getCumulativeEffects = recipe => {
    const effects = getEffects(recipe);
    statTypes.forEach(s => effects[s] *= getMultiplier(recipe));

    return effects;
};



const tournamentSelect = candidates => {
    const selection = [];
    for (let i = 0; i < candidates.length - 1; i += 2) {
        const e0 = getCumulativeEffects(candidates[i]);
        const e1 = getCumulativeEffects(candidates[i + 1]);

        let [ winner, log ] = fight(e0, e1);
        selection.push({ winner: i + winner, log });
    }

    return selection;
};

const generateName = ingredients => {
    let remaining = ingredients.map(id => store.getters.ingredientTable[id]);

    let name = [];

    if (Random.coin(0.6)) name.push(Random.pick([
        "Divine", "Double", "Oozing", "Smokey", "Arousing", "Vitalizing", "Tonic", "Special",
        "Potent", "Invigorating",  "Throbbing", "Traditional", "Original", "Perfect", "Amazing",
        "Spongy", "Chewy", "Crunchy", "Crispy", "Crazy", "Golden", "Easy", "Soft", "Light", 
        "Emboldening", "Heartening", "Empowering", "Virile", "Lethal", "Intense", "Rare", "Mild",
        "Wild", "Menacing", "Royal", "DiGiorno's", "Patent pending", "Fried", "Flaming hot",
        "Grandma's", "Patented", "The famous", "Tactical", "Healthy"
    ]));

    do {
        let pick = Random.pick(remaining, remaining.map(ing => Number(ing.cost)));
        name.push(pick.name);
        remaining = remaining.filter(ing => ing.name != pick.name);
    } while(remaining.length && Random.coin(0.3))

    name.push(Random.pick([
        "cookie", "cookies", "squares", "delights", "shortbread", "bar", "wafer", "wafers",
        "bars", "bites", "dough cookies", "snack", "biscuit", "biscuits", "cracker", "crackers",
        "fingers", "tongues", "rings", "crinkles", "balls", "rolls"
    ]));

    return name.join(" ");
};

const generateInstructions = ingredients => {
    let dough = [ ...ingredients ];

    let knead = [];
    while (dough.length > 2 && Random.coin(0.4)) {
        dough = Random.shuffle(dough);
        knead.push(dough.pop());
    }
    let coat = [];
    while (dough.length > 2 && Random.coin(0.3)) {
        dough = Random.shuffle(dough);
        coat.push(dough.pop());
    }
    let sprinkle = [];
    while (dough.length > 2 && Random.coin(0.3)) {
        dough = Random.shuffle(dough);
        sprinkle.push(dough.pop());
    }
    let glaze = [];
    while (dough.length > 2 && Random.coin(0.3)) {
        dough = Random.shuffle(dough);
        glaze.push(dough.pop());
    }

    let instructions = [
        { type: "mix", ingredients: dough, variant: Random.pick([ "whisk", "mix", "blend" ]) },
        { type: "knead", ingredients: [], variant: "knead" },
    ];

    if (knead.length) {
        instructions.push({
            type: "knead-in", ingredients: knead, variant: ""
        });
    }

    if (Random.coin(0.3)) {
        let time = Random.coin(0.7)
            ? `${Math.floor(Random.uniformIn(3,16))*10} minutes`
            : `${Math.floor(Random.gaussian()*10) + 1} days`;
        instructions.push({ type: Random.pick([ "cool", "rest" ]), time });
    }

    instructions.push({
        type: "shape", variant: Random.pick([ "ball", "flattened", "cutter" ])
    });

    if (coat.length) {
        instructions.push({
            type: "coat", ingredients: coat, variant: ""
        });
    }

    let ovenTime = `${Math.floor(Random.uniformIn(3,18))*10} minutes`;
    let temperature = `${Math.floor(Random.uniformIn(12,42))*10}`;
    instructions.push({
        type: "bake", time: ovenTime, temperature
    });

    if (glaze.length) {
        instructions.push({
            type: "glaze", ingredients: glaze, variant: ""
        });
    }
    if (sprinkle.length) {
        instructions.push({
            type: "sprinkle", ingredients: sprinkle, variant: ""
        });
    }


    return instructions;
};

const crossbreed = (r0, r1) => {
    // 2-point cross-over
    let p0 = Math.floor(Math.random()*r0.length);
    let p1 = Math.floor(Math.random()*r1.length);

    let c1 = [ ...r0.slice(0, p0), ...r1.slice(p1) ];
    let c2 = [ ...r1.slice(0, p1), ...r0.slice(p0) ];

    return [ c1, c2 ];
}


const breed = (parents, offspringCount) => {
    const offspring = [];
    for (let i = 0; offspring.length < offspringCount; i+= 2) {
        let p0 = parents[i % parents.length];
        let p1 = parents[(i + 1) % parents.length];

        let childIngredients = crossbreed(p0.ingredients, p1.ingredients);

        offspring.push(
            { name: generateName(childIngredients[0]), ingredients: childIngredients[0] },
            { name: generateName(childIngredients[1]), ingredients: childIngredients[1] },
        );
    }

    return offspring;
};

const mutate = (individual, rate) => {
    let { ingredients } = individual;
        
    ingredients = ingredients.flatMap(ingredient => {
        if (Math.random() <= rate/ingredients.length) { // do mutation
            let operators = [ "insert", "replace" ];
            if (ingredients.length > 3) operators.push("remove");

            let mode = Random.pick(operators);
            let newIngredient = Random.pick(store.state.ingredientList).id;
            switch (mode) {
                case "remove": return [];
                case "insert": return [ ingredient, newIngredient ];
                case "replace": return [ newIngredient ];
            }
        }
        else return [ ingredient ];
    });

    ingredients = Array.from(new Set(ingredients));
    ingredients.sort((a, b) => {
        a = store.getters.ingredientTable[a];
        b = store.getters.ingredientTable[b];

        return a.name < b.name ? -1 : 1;
    });

    let color = Random.uniformIn(0, 360);
    let saturation = Random.uniformIn(5, 150);
        
    return {
        ...individual,
        ingredients,
        name: generateName(ingredients),
        render: {
            color, saturation,
            scale: Random.uniformIn(0.8, 3),
            rotation: Random.uniform(),
            icon: Random.pick([ "🥨", "🥟", "🍪", "🍩", "🍘", "🍥" ])
        },
        instructions: generateInstructions(ingredients)
    };
};

export const executeStep = population => {
    let mutated = population.map(r => mutate(r, 1));
    let round1 = tournamentSelect(mutated);
    let round1Recipes = round1.map(res => mutated[res.winner]);    
    
    let round2 = tournamentSelect(round1Recipes);
    let round2Recipes = round2.map(res => round1Recipes[res.winner]);

    let rounds = [
        { results: round1, recipes: round1Recipes },
        { results: round2, recipes: round2Recipes },
    ];

    let offspring = breed(round2Recipes, population.length);

    return { mutated, rounds, offspring };
}