<template>
  <main class="page page-recipes">
    <header>
      <h2 class="section-title">Inspiring recipes</h2>

    </header>
    <div class="text-section" v-if="ingredientList.length == 0">
      <p>
        Please add some ingredients first on the <router-link :to="ingredients">ingredient page</router-link>.
      </p>
    </div>
    <template v-else>
      <div class="text-section">
        <p>
          These recipes form part of the inspiring set for Darwins Bakery. These recipes are used to create the initial generation of our evolutionary algorithm. Each recipe consists of a list of recipes and a name. You can add, change or remove recipes here. Recipe data is stored locally in your browser. Use the import/export functionality to share your ingredients with others.
        </p>
      </div>
      <div class="control-bar">
        <button @click="startNewRecipe">New recipe</button>
        <div class="button-group">
          
          <button @click="reset">Reset</button> |
          <button @click="showModal = 'export'">Export</button>
          <button @click="showModal = 'import'">Import</button>
        </div>
      </div>
      <!-- <recipe-list :recipes="recipes" @click-recipe="editRecipe" /> -->
      <ul>
        <li class="recipe-list-entry" v-for="r in recipes" :key="r.id" >
          <button class="icon-button" @click="removeRecipe(r)" title="Remove recipe">
              🗑
          </button>
          <div @click="editRecipe(r)" class="recipe-name">{{ r.name }}</div>
        </li>
      </ul>
      <modal v-if="showModal == 'editor'" title="New Recipe" @close="showModal = null">
        <label for="recipe-editor-name">Name</label>
        <input id="recipe-editor-name" v-model="recipeEditor.name" />
        <h2>Ingredients</h2>
        <div class="recipe-ingredient-selector">
          <div class="recipe-ingredient-column">
            <span class="recipe-column-title">Available</span>
            <ul class="recipe-ingredient-list">
              <li
                v-for="ing in ingredientsNotInRecipe"
                :key="ing.id"
                @click="addIngredientToRecipe(ing)"
              >
                {{ ing.name }}
              </li>
            </ul>
          </div>
          <div class="recipe-ingredient-column">
            <span class="recipe-column-title">Included</span>
            <ul class="recipe-ingredient-list">
              <li
                v-for="ing in ingredientsInRecipe"
                :key="ing.id"
                @click="removeIngredientFromRecipe(ing)"
              >
                {{ ing.name }}
              </li>
            </ul>
          </div>
        </div>

        <template v-slot:footer>
            <button @click="saveRecipe()">Save</button>
        </template>
      </modal>

      <modal v-if="showModal == 'export'" title="Export" @close="showModal = null">
        <button @click="copyExport">Copy to clipboard</button>
          <pre ref="exportText">
              {{ exportStr }}
          </pre>
      </modal>
      <modal v-if="showModal == 'import'" title="Export" @close="showModal = null">
          <textarea v-model="importStr" />
          <template v-slot:footer>
              <button @click="doImport">Import</button>
          </template>
      </modal>
    </template>
  </main>
</template>

<script>
import { mapState } from "vuex";

import cookiemon from "@/cookiemon.js";
import { deepCopy } from "@/utils.js";

import RecipeList from "@/components/RecipeList.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: { RecipeList, Modal },
  data() {
    return {
      recipes: deepCopy(this.$store.state.recipeList),
      showModal: false,
      recipeEditor: null,
      importStr: '',
    };
  },
  computed: {
    ...mapState([ "ingredientList" ]),
    ingredientsInRecipe() {
      if (!this.recipeEditor) return [];
      return this.ingredientList.filter(ing => this.recipeEditor.ingredients.includes(ing.id));
    },
    ingredientsNotInRecipe() {
      if (!this.recipeEditor) return this.recipeList;
      return this.ingredientList.filter(ing => !this.recipeEditor.ingredients.includes(ing.id));
    },
    exportStr() {
      return JSON.stringify(this.recipes, null, 2);
    }
  },
  methods: {
    startNewRecipe() {
      this.recipeEditor = {
        name: "",
        ingredients: [],
      };
      this.showModal = 'editor';
    },
    editRecipe({ recipe }) {
      this.recipeEditor = { ...recipe };
      this.showModal = 'editor';
    },
    addIngredientToRecipe(ing) { this.recipeEditor.ingredients.push(ing.id); },
    removeIngredientFromRecipe(ing) {
      this.recipeEditor.ingredients = this.recipeEditor.ingredients.filter(
        rIng => rIng != ing.id);
    },
    saveRecipe() {
      let promise;
      if (!this.recipeEditor.id) {
        promise = this.$store.dispatch("addRecipe", this.recipeEditor);
      } else {
        promise = this.$store.dispatch("updateRecipe", this.recipeEditor);
      }
      promise.then(() => this.recipes = deepCopy(this.$store.state.recipeList));

      this.showModal = null;
    },
    removeRecipe(recipe) {
      this.$store.dispatch("removeRecipe", recipe.id)
        .then(() => this.recipes = deepCopy(this.$store.state.recipeList));
    },
    reset() {
      this.$store.dispatch("resetRecipes")
        .then(() => this.recipes = deepCopy(this.$store.state.recipeList));
    },
    copyExport() {
        window.getSelection().selectAllChildren(this.$refs.exportText);
        document.execCommand("copy");
    },

    doImport() {
        try {
            let data = JSON.parse(this.importStr);
            this.$store.dispatch("importRecipes", data)
                .then(() => {
                    this.recipes = deepCopy(this.$store.state.recipeList);
                    this.showModal = null;
                });
        } catch {}
    }
  },
};
</script>

<style lang="scss">
.recipe-modal {
  .recipe-ingredient-selector {
    display: flex;
  }

  .recipe-ingredient-list {
    width: 200px;
    height: 200px;
    overflow: auto;

    user-select: none;

    li {
      transition: all 0.2s;
    }

    li:hover {
      background: rgba(255, 255, 210, 0.75);
    }
  }

  .recipe-column-title {
    display: block;
    margin-bottom: 8px;
    border-bottom: 1px solid #ccc;
  }
}

.recipe-list-entry {
  display: flex;
  align-items: baseline;
  margin: 4px 0;
  
  .recipe-name {
    margin-left: 4px;
    padding-left: 4px;
  }
}
</style>