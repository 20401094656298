<template>
    <main class="page page-ingredients">
        <header>
            <h2 class="section-title">Ingredient knowledgebase</h2>
        </header>

        <div class="text-section">
            <p>
                Manage the ingredients used to generate recipes. Be cautious with removing ingredients used in recipes in the inspiring set! Ingredient data is stored locally in your browser. Use the import/export functionality to share your ingredients with others.
            </p>
        </div>
        <div class="button-group">
            <span>Load ingredients:</span>
            <button @click="load('basic')">Basic</button>
            <button @click="load('fantasy')">Fantasy</button> |
            <button @click="openExport()">Export</button>
            <button @click="showModal = 'import'">Import</button>
        </div>
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <!-- <th>Type</th> -->
                    <th>Health</th>
                    <th>Stamina</th>
                    <th>Strength</th>
                    <th>Speed</th>
                    <th>Evasion</th>
                    <th>Toughness</th>
                    <th>Cost</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="ing in ingredientList" :key="ing.id">

                    <td>
                        <button class="icon-button" @click="removeIngredient(ing)" title="Remove ingredient">
                            🗑
                        </button>
                        <input @change="saveIngredient(ing)" v-model="ing.name">
                    </td>
                    <!-- <td>{{ ing.type }}</td> -->
                    <td><input class="stat-field" @change="saveIngredient(ing)" v-model="ing.health" /></td>
                    <td><input class="stat-field" @change="saveIngredient(ing)" v-model="ing.stamina" /></td>
                    <td><input class="stat-field" @change="saveIngredient(ing)" v-model="ing.strength" /></td>
                    <td><input class="stat-field" @change="saveIngredient(ing)" v-model="ing.speed" /></td>
                    <td><input class="stat-field" @change="saveIngredient(ing)" v-model="ing.evasion" /></td>
                    <td><input class="stat-field" @change="saveIngredient(ing)" v-model="ing.toughness" /></td>
                    <td><input class="stat-field" @change="saveIngredient(ing)" v-model="ing.cost" /></td>
                </tr>
            </tbody>
        </table>

        <form @submit.prevent="addIngredient">
            <span>
            <label for="new-ingredient-name">Name:</label>
            <input id="new-ingredient-name" v-model="newIngredient.name" />
            </span>
            <span>
            <!-- <label for="new-ingredient-type">Type:</label>
            <select id="new-ingredient-type" v-model="newIngredient.type">
                <option>Misc</option>
                <option>Binding</option>
                <option>Fat</option>
            </select> -->
            </span>

            <button type="submit">Add ingredient</button>
        </form>

        <modal v-show="showModal == 'export'" title="Export" @close="showModal = null">
            <button @click="copyExport">Copy to clipboard</button>
            <pre ref="exportText">
                {{ exportStr }}
            </pre>
        </modal>
        <modal v-if="showModal == 'import'" title="Export" @close="showModal = null">
            <textarea v-model="importStr" />
            <template v-slot:footer>
                <button @click="doImport">Import</button>
            </template>
        </modal>
    </main>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import * as cookiemon from "@/cookiemon.js";
import { deepCopy } from "@/utils.js";

import Modal from "@/components/Modal.vue";

import basicIngredients from "@/assets/ingredients.json";
import fantasyIngredients from "@/assets/fantasy-ingredients.json";


export default {
    components: { Modal },
    data() {
        return {
            ingredientList: deepCopy(this.$store.state.ingredientList),
            newIngredient: {
                name: "",
                type: "Misc"
            },
            showModal: null,
            importStr: ''
        };
    },
    computed: {
        exportStr() {
            return JSON.stringify(this.ingredientList, null, 2);
        }
    },
    methods: {
        addIngredient() {
            this.$store.dispatch("addIngredient", {
                ...this.newIngredient, ...cookiemon.emptyStats, cost: 0
            })
                .then(() => this.ingredientList = deepCopy(this.$store.state.ingredientList));
        },
        removeIngredient(ing) {
            this.$store.dispatch("removeIngredient", ing.id)
                .then(() => this.ingredientList = deepCopy(this.$store.state.ingredientList));
        },
        saveIngredient(ing) {
            this.$store.dispatch("updateIngredient", ing);
        },
        load(name) {
            let promise = Promise.resolve();
            switch (name) {
                case "basic": {
                    promise = this.$store.dispatch("importIngredients", basicIngredients);
                } break;
                case "fantasy": {
                    promise = this.$store.dispatch("importIngredients", fantasyIngredients);
                } break;
            }
            promise.then(() => this.ingredientList = deepCopy(this.$store.state.ingredientList));
        },
        doImport() {
            try {
                let data = JSON.parse(this.importStr);
                this.$store.dispatch("importIngredients", data)
                    .then(() => {
                        this.ingredientList = deepCopy(this.$store.state.ingredientList);
                        this.showModal = null;
                    });
            } catch {}
        },
        copyExport() {
            window.getSelection().selectAllChildren(this.$refs.exportText);
            document.execCommand("copy");
        },
        openExport() {
            this.showModal = 'export';

            console.log(this.$refs.exportText)
        }
    }
}
</script>

<style lang="scss">

.stat-field {
  width: 48px;
  text-align: right;
}


</style>